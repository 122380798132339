import styled from 'styled-components'

const ProductsSliderSectionStyle = styled.div`
	--prTopContMarginBottom: var(--sp9x);
	--prSlideWidth: 33.333%;
	--prSlideMargin: var(--sp5x);
	--prDragItemSize: var(--sp25x);
	--cocktailsSectionTitleWidth: var(--sp189x);

	display: flex;
	flex-direction: column;
	margin-bottom: calc(var(--prDragItemSize) / -2);
	overflow: hidden;

	.ps-top-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: var(--prTopContMarginBottom);
	}

	.ps-slider-container {
		position: relative;
		margin: 0 calc(var(--prSlideMargin) / -2 - var(--contPaddingLR)) calc(var(--prDragItemSize) / 2);

		.ps-slider-inner-container {
			overflow-x: hidden;

			.swiper {
				overflow: visible;
				cursor: ${(props) => (props.imagesQuantity > props.dragNumberSize ? 'none' : 'default')};
				margin: 0 var(--contPaddingLR);

				.swiper-slide {
					width: var(--prSlideWidth);
					padding: 0 calc(var(--prSlideMargin) / 2);
					height: auto;
					pointer-events: none;
					cursor: ${(props) => (props.imagesQuantity > props.dragNumberSize ? 'none' : 'default')};

					.ps-slide {
						height: 100%;
						pointer-events: all;

						* {
							cursor: ${(props) => (props.imagesQuantity > props.dragNumberSize ? 'none' : 'pointer')};
						}
					}
				}
			}

			.ps-drag-container {
				position: absolute;
				z-index: 3;
				pointer-events: none;
				transform: scale(0);

				@media (hover: none) {
					display: none;
				}

				.ps-drag {
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: var(--prDragItemSize);
					height: var(--prDragItemSize);
					border-radius: 50%;
					color: var(--primaryColor15);
					background-color: var(--primaryColor9);
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	.products-section-title {
		width: var(--cocktailsSectionTitleWidth);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
		--prTopContMarginBottom: var(--sp7x);
		--prSlideWidth: 33.333%;
		--prSlideMargin: var(--sp5x);
		--prDragItemSize: var(--sp18x);
		--cocktailsSectionTitleWidth: var(--sp141x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
		--prTopContMarginBottom: var(--sp6x);
		--prSlideWidth: 33.333%;
		--prSlideMargin: var(--sp5x);
		--prDragItemSize: var(--sp16x);
		--cocktailsSectionTitleWidth: var(--sp110x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
		--prTopContMarginBottom: var(--sp6x);
		--prSlideWidth: 33.333%;
		--prSlideMargin: var(--sp4x);
		--prDragItemSize: var(--sp16x);
		--cocktailsSectionTitleWidth: var(--sp108x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
		--prTopContMarginBottom: var(--sp5x);
		--prSlideWidth: 33.333%;
		--prSlideMargin: var(--sp4x);
		--prDragItemSize: var(--sp14x);
		--cocktailsSectionTitleWidth: var(--sp96x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
		--prTopContMarginBottom: var(--sp5x);
		--prSlideWidth: 30.533%;
		--prSlideMargin: var(--sp4x);
		--prDragItemSize: var(--sp14x);
		--cocktailsSectionTitleWidth: var(--sp76x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
		--prTopContMarginBottom: var(--sp5x);
		--prSlideWidth: 45.2%;
		--prSlideMargin: var(--sp3x);
		--prDragItemSize: var(--sp14x);
		--cocktailsSectionTitleWidth: 100%;

		.ps-top-container {
			align-items: center;
			justify-content: center;
		}

		.ps-btn-cont {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: var(--sp5x);
		}

		.products-section-title {
			text-align: center;
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--prTopContMarginBottom: var(--sp5x);
		--prSlideWidth: 86.9%;
		--prSlideMargin: var(--sp1x);
		--prDragItemSize: var(--sp14x);
		--cocktailsSectionTitleWidth: 100%;

		.ps-top-container {
			align-items: center;
			justify-content: center;
		}

		.ps-btn-cont {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: var(--sp4x);
		}

		.products-section-title {
			text-align: center;
		}
	}
`

export default ProductsSliderSectionStyle
