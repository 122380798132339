import { memo as Memo } from 'react';

//* Components
import Text from 'components/common/Text';
import Image from 'components/common/Image';
import CustomLink from 'components/common/CustomLink';

//* Style
import ProductCardStyle from './style';

const ProductCard = Memo(({ className, image, name, description, url }) => {
	return (
		<ProductCardStyle className={`${className || ''} pc-item white-bg`}>
			<CustomLink url={url}>
				<Image
					src={image?.src}
					alt={image?.alt}
					className={'contain'}
				/>

				<Text
					tag={`p`}
					className={`p p1 minion3-semiBold primary-color9 uppercase align-center pc-name`}
					text={name}
				/>

				<Text
					tag={`p`}
					className={`p p3 notoSans-regular primary-color9 align-center pc-desc`}
					text={description}
				/>
			</CustomLink>
		</ProductCardStyle>
	);
});

export default ProductCard;
