import styled from 'styled-components'

const ProductCardStyle = styled.div`
	--pcPadT: var(--sp6x);
	--pcPadB: var(--sp5x);
	--pcPadRL: var(--sp5x);
	--pcBorderRadius: var(--sp3x);
	--pcNameMTop: var(--sp6x);
	--pcDescMTop: var(--sp1-5x);
	--pcImageHeight: 118.1%;

	height: 100%;
	padding: var(--pcPadT) var(--pcPadRL) var(--pcPadB) var(--pcPadRL);
	border-radius: var(--pcBorderRadius);
	border: 1px solid var(--white);
	transition: border var(--trTime);

	.image-cont {
		--proportion: var(--pcImageHeight);
		border-radius: var(--pcBorderRadius);
		overflow: hidden;
	}

	.pc-name {
		margin-top: var(--pcNameMTop);
	}

	.pc-desc {
		margin-top: var(--pcDescMTop);
	}

	@media (hover: hover) {
		&:hover {
			border: 1px solid var(--primaryColor9);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--pcPadT: var(--sp4x);
		--pcPadB: var(--sp4x);
		--pcPadRL: var(--sp3x);
		--pcBorderRadius: var(--sp3x);
		--pcNameMTop: var(--sp4x);
		--pcDescMTop: var(--sp1x);
		--pcImageHeight: 118.3%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--pcPadT: var(--sp3x);
		--pcPadB: var(--sp3x);
		--pcPadRL: var(--sp2x);
		--pcBorderRadius: var(--sp3x);
		--pcNameMTop: var(--sp3x);
		--pcDescMTop: var(--sp1x);
		--pcImageHeight: 118.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--pcPadT: var(--sp3x);
		--pcPadB: var(--sp2x);
		--pcPadRL: var(--sp2x);
		--pcBorderRadius: var(--sp3x);
		--pcNameMTop: var(--sp3x);
		--pcDescMTop: var(--sp1x);
		--pcImageHeight: 118.4%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--pcPadT: var(--sp2-5x);
		--pcPadB: var(--sp2x);
		--pcPadRL: var(--sp2x);
		--pcBorderRadius: var(--sp3x);
		--pcNameMTop: var(--sp2-5x);
		--pcDescMTop: var(--sp1x);
		--pcImageHeight: 118.7%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--pcPadT: var(--sp2x);
		--pcPadB: var(--sp1-5x);
		--pcPadRL: var(--sp1-5x);
		--pcBorderRadius: var(--sp2x);
		--pcNameMTop: var(--sp2x);
		--pcDescMTop: var(--sp1x);
		--pcImageHeight: 119.1%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--pcPadT: var(--sp3x);
		--pcPadB: var(--sp2x);
		--pcPadRL: var(--sp2x);
		--pcBorderRadius: var(--sp2x);
		--pcNameMTop: var(--sp3x);
		--pcDescMTop: var(--sp1-5x);
		--pcImageHeight: 118.1%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--pcPadT: var(--sp3x);
		--pcPadB: var(--sp2x);
		--pcPadRL: var(--sp2x);
		--pcBorderRadius: var(--sp2x);
		--pcNameMTop: var(--sp3x);
		--pcDescMTop: var(--sp1-5x);
		--pcImageHeight: 115.7%;
	}
`

export default ProductCardStyle
