import styled from 'styled-components'

const PageStyle = styled.main`
	> *:first-child {
		padding-top: ${(props) => props.headerHeight}px;
	}

	&.single-news-and-events {
		.event-background {
			padding-top: ${(props) => props.headerHeight}px;
			margin-top: ${(props) => `-${props.headerHeight}px`};
		}
	}
`
export default PageStyle
