import { memo as Memo, useMemo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer';

//* Style
import PageStyle from './style';
import Head from 'next/head';

const Page = Memo(({ meta, winWidth, seoProps, ...props }) => {
	//! State
	const [headerHeight, setHeaderHeight] = useState();

	//! Router
	const router = useRouter();

	//! Host
	const host = process.env.host;

	//! Type
	const type = 'website';

	//! URL
	const url = useMemo(() => host + (router.locale !== 'en' ? '/' + router.locale : '') + router.asPath, [router]);

	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	useEffect(() => {
		setHeaderHeight(document.querySelector('.header')?.clientHeight);
	}, [winWidth]);

	//! Title
	const title = useMemo(() => meta?.title || 'Ohanyan Brandy Company', [meta?.title]);

	//! Description
	const description = useMemo(() => meta?.description || "The company produces exclusive strong alcoholic beverages from natural fruits, brandy, wine, and vodka. The best specialists using ultra-modern technologies are working hard to produce our exclusive collection. The collection of ARTSAKH's strong alcoholic beverages is of great elegance and richness and offers an incredibly interesting and unique tasting experience.", [meta?.description]);

	return (
		<>
			<NextSeo
				title={title}
				description={description}
				canonical={url}
				type={type}
				additionalMetaTags={[
					{
						name: 'keywords',
						content: meta?.keywords,
					},
				]}
				openGraph={{
					type,
					url: url,
					title: title,
					description: description,
					images: [{ url: meta?.image?.src || process.env.host + '/images/Ohanyan_Brandy.png', alt: meta?.image?.alt }],
				}}
				twitter={{
					handle: '@handle',
					site: '@site',
					cardType: 'summary_large_image',
				}}
				{...seoProps}
			/>
			<Head>
				<link
					rel='alternate'
					href={`${host}${router.asPath}`}
					hrefLang='x-default'
				/>

				{router.locales.map((loc) => (
					<link
						key={loc}
						rel='alternate'
						href={`${host + (loc !== 'en' ? '/' + loc : '') + router.asPath}`}
						hrefLang={loc}
					/>
				))}
			</Head>
			<PageStyle
				headerHeight={headerHeight}
				className={`page ${props.className || ''}`}>
				{props.children}
			</PageStyle>
		</>
	);
});

export default withUIContext(Page, ['winWidth']);
